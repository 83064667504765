<template>
  <section>
    <ShoppingProgress :step.sync="step" />
    <section class="pb-8" v-if="ShoppingData.shoppings.length > 0">
      <!-- 訂單明細 -->
      <OrderDetail
        :IsReady="IsReady"
        :step.sync="step"
        :ShoppingData.sync="ShoppingData"
        @pushDelete="Delete"
      >
      </OrderDetail>
      <div class="container">
        <!-- 上一步/下一步按鈕 -->
        <div class="mx-3 mx-md-6">
          <div class="d-flex justify-content-end">
            <a
              href=""
              class="btn btn-primary btn-hover-shadow px-6 py-2 py-md-3"
              @click.prevent="next"
              >下一步</a
            >
          </div>
        </div>
      </div>
    </section>
    <section class="pb-8" v-else>
      <div class="container">
        <div class="order-bg fail mb-3 mb-md-8">
          <div>
            購物車是空的
          </div>
        </div>
        <div class="container">
          <!-- 上一步/下一步按鈕 -->
          <div class="mx-3 mx-md-6">
            <div class="d-flex justify-content-end">
              <router-link class="btn btn-primary btn-hover-shadow px-6 py-2 py-md-3" to="/course">
                前往購買課程
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
import OrderDetail from '@/components/Shopping/OrderDetail.vue';
import ShoppingProgress from '@/components/ShoppingProgress.vue';
import {
  GetShoppingCart,
  DeleteShoppingCartByCourse,
  SaveShoppingCart,
} from '@/api/ShoppingCartApi';

export default {
  components: {
    OrderDetail,
    ShoppingProgress,
  },
  data() {
    return {
      step: 0,
      shoppingCount: 0,
      ShoppingData: {
        orderId: 0,
        shoppings: [
          {
            courseId: 0,
            courseName: '',
            courseOriPrice: 0,
            coursePrice: 0,
            classCount: 0,
            shoppingCartDetails: [
              {
                classId: 0,
                className: '',
                subjectName: '',
                teacherName: '',
              },
            ],
          },
        ],
        discountId: 0,
        discountMode: 0,
        discountName: '',
        discountCode: '',
        discountType: 0,
        discountPrice: 0,
        discountPercent: 0,
        discountMoney: 0,
        orderName: '',
        orderPhone: '',
        orderZipCode: '',
        orderAddress: '',
        orderNote: '',
        orderPayMode: 0,
        orderPayModeName: '',
      },
      IsReady: false,
    };
  },
  created() {
    this.GetShoppingCart();
  },
  methods: {
    GetShoppingCart() {
      GetShoppingCart().then((response) => {
        if (response.data.statusCode === 0) {
          this.$bus.$emit('getShoppingCartCount');
          this.ShoppingData = response.data.data;
          this.ShoppingData.shoppings.forEach((item) => {
            if (item.courseType === 2) {
              item.optionalChooseList.forEach((item2) => {
                // eslint-disable-next-line no-param-reassign
                this.$set(item2, 'teacherChoose', []);
                item2.optionalTeacherList.forEach((item3) => {
                  // eslint-disable-next-line no-param-reassign
                  if (item3.hasTeacher) {
                    item2.teacherChoose.push(item3.teacherId);
                  }
                });
              });
            }
          });
          this.IsReady = true;
        }
      });
    },
    Delete(id, name) {
      this.$confirm(`確定要將[${name}]從購物車中刪除 ?`, '提示', {
        confirmButtonText: '確認',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          DeleteShoppingCartByCourse(id).then((response) => {
            if (response.data.statusCode === 0) {
              this.GetShoppingCart();
            }
          });
        })
        .catch(() => {});
    },
    next() {
      const postData = { ...this.ShoppingData };
      let error = false;
      let havType2 = false;
      postData.shoppings.forEach((item) => {
        if (item.courseType === 2) {
          havType2 = true;
          item.optionalChooseList.forEach((item2) => {
            // 檢核師資是否都選取
            if (item2.teacherChoose.length !== item2.courseOptionalLimit) {
              error = true;
            }
            item2.optionalTeacherList.forEach((item3) => {
              // eslint-disable-next-line no-param-reassign
              item3.hasTeacher = this._.includes(item2.teacherChoose, item3.teacherId);
            });
          });
        }
      });

      if (error) {
        this.$message({ showClose: true, type: 'error', message: '請選擇自選講師' });
        return;
      }
      if (havType2) {
        this.$confirm('自選科目講師確認後將不可異動，請確認勾選講師是否正確?', '提示', {
          confirmButtonText: '確認',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          SaveShoppingCart(postData).then((response) => {
            if (response.data.statusCode === 0) {
              this.$router.push({ name: 'Step2', params: { check: 'true' } });
            }
          });
        });
      } else {
        SaveShoppingCart(postData).then((response) => {
          if (response.data.statusCode === 0) {
            this.$router.push({ name: 'Step2', params: { check: 'true' } });
          }
        });
      }
    },
  },
};
</script>

<style></style>
